// import classRoomStore from 'store/classRoomStore';

/**
 * Listen the topic and execute the executor function.
 * This will called when some commend received the status update
 *
 * @function
 * @memberof Mqtt.CommandStatus#
 * @ignore
 * @param {KaisClanSchemaCommandStatus} packet - The payload in JSON structure.
 * @see {@link Mqtt.CommandStatus.schema JSON structure}
 * @todo Remove current command from queue, payload.id
 * @todo Execute the next command in queue if exist
 */
export const executor = (packet) => {
  // eslint-disable-next-line no-unused-vars
  const { payload } = packet;
};

export default executor;
