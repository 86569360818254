/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
export default () => Promise.resolve({
  info: {
    RTL: false,
    thousand_place: ',',
    currency_symbol: '₣',
    fist_day_of_week: 7,
    // date_time_format: '|',
  },
  translations: {
    "components/TopMenu/TopMenu.jsx": {
      "Scan the class code with either the &#34;Kai&#39;s Eye&#34; phone": "Numérisez le code de classe avec l’icône de &#34; Kai &#39 ;'s &#34; téléphone",
      "using the &#34;Kai&#39;s Virtual&#34; viewer found in the app store.": "en utilisant le &#34; s Virtual &#34; s Virtual &#34; s spectateur trouvé dans l'App Store.",
      "or": "ou",
      "Students": "Élèves",
      "My Robots": "Mes Robots",
      "Robots Manager": "robots Directeur",
      "Add account": "Ajouter un compte",
      "Switch Classroom": "commutateur classe",
      "Show Class Code": "Afficher le code de classe",
      "Logout": "Se déconnecter",
      "Privacy Policy": "Politique de confidentialité",
      "About": "À propos",
      "Version": "Version",
      "Disclaimer": "Avertissement",
      "RTTTL ringtones files used are for personal enjoyment only.": "RTTTL sonneries fichiers utilisés sont pour le plaisir personnel.",
      "All copyright belongs to its respective author.": "Tout droit d'auteur appartient à son auteur respectif.",
      "Microsoft, Windows, and Windows Vista are either registered trademarks or trademarks of Microsoft Corporation in the United States and/or other": "Microsoft, Windows et Windows Vista sont des marques déposées ou des marques de Microsoft Corporation aux États-Unis et / ou d'autres",
      "App Store is a service mark of Apple Inc.": "App Store est une marque de service d'Apple Inc.",
      "“Android”, Google Play and Google Play logo are trademarks or registered trademarks of Google Inc.": "« Android », Google Play et Google Play logo sont des marques ou des marques déposées de Google Inc.",
      "Wi-Fi, the Wi-Fi CERTIFIED logo are trademarks or registered trademarks of Wi-Fi Alliance.": "Wi-Fi, le logo Wi-Fi CERTIFIED sont des marques commerciales ou des marques déposées de Wi-Fi Alliance.",
      "JAVASCRIPT is a registered trademark of Oracle and/or its affiliates.": "JAVASCRIPT est une marque déposée d'Oracle et / ou de ses filiales.",
      "Other names may be trademarks of their respective owners.": "D'autres noms peuvent être des marques de leurs propriétaires respectifs.",
      "Autonomous Works LTD or Kai’s Clan is not associated with Oracle and Oracle does not sponsor or endorse Autonomous Works LTD or Kai’s Clan, its": "Travaux autonomes LTD ou du clan de Kai ne sont pas associés à Oracle et Oracle ne cautionne ni ne parraine Works autonome LTD ou du clan de Kai, son"
    },
    "StatusItemClassroomCode.jsx": {
      "Scan the class code with either the &#34;Kai&#39;s Eye&#34; phone": "Numérisez le code de classe avec l’icône de &#34; Kai &#39 ;'s &#34; téléphone",
      "using the &#34;Kai&#39;s Virtual&#34; viewer found in the app store.": "en utilisant le &#34; s Virtual &#34; s Virtual &#34; s spectateur trouvé dans l'App Store.",
      "or": "ou",
      "Share": "Partager",
      "Help": "Aidez-moi"
    },
    "gotoLoginPageWhenNotAuthorized.js": {
      "Oops! You have been disconnected, because someone else logged into your account.": "Oops! Vous avez été déconnecté, parce que quelqu'un d'autre connecté à votre compte."
    },
    "api/index.js": {
      "No internet connection": "Pas de connexion Internet",
      "Check your internet connection": "Vérifiez votre connection internet",
      "Oops! Something went wrong. Try closing and reopening your browser.": "Oops! Quelque chose a mal tourné. Essayez de fermer et de rouvrir votre navigateur.",
      "The request which you sent has timeout.": "La demande que vous avez envoyé a délai.",
      "Please, wait few minutes and try again.": "S'il vous plaît, attendez quelques minutes et essayez à nouveau."
    },
    "ExecuteButton.jsx": {
      "STOP": "ARRÊTEZ",
      "RUN": "COURIR",
      "FINISHED": "FINI"
    },
    "Footer.jsx": {
      "AUTONOMOUS WORKS LIMITED": "AUTONOME TRAVAUX LIMITED",
      "Help": "Aidez-moi",
      "Privacy & Terms": "Confidentialité et modalités"
    },
    "FormLogin/index.jsx": {
      "E-mail not verified yet!": "E-mail pas encore vérifié!",
      "Login with Google": "Connectez-vous avec Google",
      "Login": "S'identifier"
    },
    "ImageUploader.jsx": {
      "Files need to be smaller that 1MB": "Les fichiers doivent être plus petits que 1MB",
      "Project category cover": "couverture Catégorie de projet",
      "Select or drag the image here": "Sélectionnez ou faites glisser l'image ici"
    },
    "MediaItem.jsx": {
      "Uploading": "Téléchargement"
    },
    "MediaLibrary.jsx": {
      "Image library is empty": "Bibliothèque d'images est vide",
      "Drag your image here to upload": "Faites glisser votre image ici pour télécharger"
    },
    "MediaModal.jsx": {
      "This image will be removed from the library!": "Cette image sera retirée de la bibliothèque!",
      "These {{number}} images will be removed from the library!": "Celles-ci {{number}} les images seront supprimées de la bibliothèque!",
      "Are you sure?": "Êtes-vous sûr?",
      "Yes, delete it!": "Oui, supprimez-le!",
      "Insert {{number}} images": "Insérer {{number}} images",
      "No image is selected.": "Aucune image est sélectionnée.",
      "Image Library": "Bibliothèque d'images",
      "Remove {{number}} image": "Retirer {{number}} image"
    },
    "NoData.jsx": {
      "Oops... nothing here!": "Oups ... rien ici!"
    },
    "sensorsJSONGen.js": {
      "Digital / Analog": "Numérique / analogique",
      "Bright LED": "Bright LED",
      "4 LED RGB Strip(RGB-LED)": "Bande de 4 LED RVB(RGB-LED)",
      "Buzzer": "Avertisseur sonore",
      "IR Emitter": "IR Emitter",
      "Relay": "Relais",
      "LED": "LED",
      "Waterproof Temperature Sensor": "Capteur de température étanche",
      "Temperature & Humidity Sensor": "Température et humidité capteur",
      "IR Receiver": "récepteur IR",
      "Button": "Bouton",
      "Event Mode": "Mode événement",
      "Continuous Mode": "mode continu",
      "Hall Sensor": "hall Sensor",
      "Vibration Sensor": "capteur de vibrations",
      "Tilt Switch": "Commutateur d'inclinaison",
      "Knock Sensor": "Détecteur de cliquetis",
      "Touch Sensor": "Senseur tactile",
      "PIR Motion Sensor": "PIR capteur de mouvement",
      "Collision Sensor": "Capteur collision",
      "Photo Electric Counter": "Photo électrique contre",
      "Switch": "Commutateur",
      "Turn-on Event Mode": "Passez en mode Event",
      "Turn-off Event Mode": "Turn-off Event Mode",
      "IR Reflective Sensor": "Réflexion Infrarouge",
      "Rotary Angel Sensor": "Rotary Ange capteur",
      "Moisture Sensor": "Capteur d'humidité",
      "Light Sensor": "Capteur de lumière",
      "Water Sensor": "Détecteur d'eau",
      "Air Quality Sensor": "Air Quality Sensor",
      "Sound Sensor": "Capteur sonore",
      "Slide Potentiometer": "Potentiomètre à glissière",
      "UV Sensor": "UV capteur",
      "Pulse Sensor": "Capteur d'impulsion",
      "PH Sensor": "Capteur de PH",
      "MEMS Microphone": "MEMS Microphone",
      "Serial": "En série",
      "3-Axis Digital Compass": "3 axes Boussole numérique",
      "MPU6050 Accelerometer & Gyro": "MPU6050 Accéléromètre & Gyro",
      "OLED": "OLED",
      "Color Sensor": "Capteur de couleur",
      "Laser Ranging Sensor": "Capteur laser Allant",
      "4-Digit Display": "Affichage à 4 chiffres",
      "LED Matrix": "Matrice de LED",
      "I2C EEPROM": "EEPROM I2C",
      "Gesture Sensor": "Capteur Gesture",
      "BME280 Atmospheric Sensor": "BME280 atmosphérique capteur",
      "BMP180 Barometer": "Baromètre BMP180",
      "Servo": "servo",
      "Gripper": "Gripper",
      "Ultrasonic": "Ultrasonique",
      "Ultrasonic Distance Sensor": "Capteur de distance à ultrasons"
    },
    "toolboxJSONGen.js": {
      "Bits": "Morceaux",
      "Sight": "Vue",
      "Single LED": "LED unique",
      "8x8 LED Matrix": "Matrice DEL 8x8",
      "4-Digit Display": "Affichage à 4 chiffres",
      "OLED Display": "Affichage OLED",
      "Color Sensor": "Capteur de couleur",
      "Sound": "Du son",
      "Buzzer": "Avertisseur sonore",
      "Sound Sensor": "Capteur sonore",
      "Environmental": "écologique",
      "Temperature & Humidity Sensor": "Température et humidité capteur",
      "Light Sensor": "Capteur de lumière",
      "UV Sensor": "UV capteur",
      "Moisture Sensor": "Capteur d'humidité",
      "Water Sensor": "Détecteur d'eau",
      "Waterproof Temperature Sensor": "Capteur de température étanche",
      "PH Sensor": "Capteur de PH",
      "Air Quality Sensor": "Air Quality Sensor",
      "BMP180 Barometer": "Baromètre BMP180",
      "BME280 Atmospheric Sensor": "BME280 atmosphérique capteur",
      "Action": "action",
      "IR Emitter": "IR Emitter",
      "IR Receiver": "récepteur IR",
      "Relay": "Relais",
      "Rotary Angle Sensor": "Rotary capteur d'angle",
      "Linear Potentiometer": "linéaire Potentiometer",
      "Photo Electric Counter": "Photo électrique contre",
      "Touch Sensor": "Senseur tactile",
      "Button": "Bouton",
      "Switch": "Commutateur",
      "IR Reflective Sensor": "Réflexion Infrarouge",
      "Collision Sensor": "Capteur collision",
      "Tilt Switch": "Commutateur d'inclinaison",
      "Pulse Sensor": "Capteur d'impulsion",
      "PIR Motion Sensor": "PIR capteur de mouvement",
      "Laser Ranging Sensor": "Capteur laser Allant",
      "3-Axis Digital Compass": "3 axes Boussole numérique",
      "MPU6050 Accelerometer & Gyro": "MPU6050 Accéléromètre & Gyro",
      "Hall Sensor": "hall Sensor",
      "Vibration Sensor": "capteur de vibrations",
      "Knock Sensor": "Détecteur de cliquetis",
      "Gesture Sensor": "Capteur Gesture",
      "I2C EEPROM": "EEPROM I2C",
      "Servo": "servo",
      "Gripper": "Gripper",
      "Distance": "Distance",
      "Ultrasonic Distance Sensor": "Capteur de distance à ultrasons",
      "Third Party": "Tierce personne"
    },
    "SensorWorkspace/index.jsx": {
      "Hardware sensor representation": "représentation du capteur de matériel"
    },
    "RobotItem.jsx": {
      "Are you sure?": "Êtes-vous sûr?",
      "This robot will be removed from this class!": "Ce robot sera retiré de cette classe!",
      "Yes, delete it!": "Oui, supprimez-le!",
      "Oops! Error on saving your work": "Oops! Erreur sur l'enregistrement de votre travail",
      "Robot": "Robot"
    },
    "RobotsItems.jsx": {
      "Oops... no robots found!": "Oups ... pas de robots trouvé!",
      "Please add at least one robot": "S'il vous plaît ajouter au moins un robot"
    },
    "SensorConfiguration.jsx": {
      "No robot were selected": "Aucun robot a été sélectionné",
      "Oops!": "Oops!",
      "Robot {{robotId}} is OFFLINE": "Robot {{robotId}} est hors-ligne",
      "SAVING...": "ÉCONOMIE...",
      "ROBOT {{robotId}} SAVED": "ROBOT {{robotId}} ENREGISTRÉ",
      "SAVE CHANGES TO ROBOT {{robotId}}": "CHANGEMENTS DE ROBOT ENREGISTRER {{robotId}}",
      "Oops... no robots selected!": "Oups ... pas de robots sélectionnés!",
      "You must select one robot": "Vous devez sélectionner un robot",
      "Robots": "robots"
    },
    "NewWorkCard.jsx": {
      "Save work": "Enregistrer le travail",
      "Awesome work": "Super travail",
      "Oops! Error on saving": "Oops! Erreur sur l'économie",
      "Save as new work": "Enregistrer comme nouveau travail"
    },
    "WorkCard.jsx": {
      "Are you sure?": "Êtes-vous sûr?",
      "You won't be able to revert this! {{workName}} will be lost.": "Vous ne serez pas en mesure de revenir ça! {{workName}} sera perdu.",
      "Yes, delete it!": "Oui, supprimez-le!",
      "No, cancel!": "Non, annuler!",
      "Removing ...": "Suppression ...",
      "Created at": "Créé à",
      "Updated at": "Mise à jour à"
    },
    "PathFindingEditorModal.jsx": {
      "Pathfinding Editor": "pathfinding Editor",
      "Loading": "Chargement"
    },
    "PathFindingForm.jsx": {
      "Your changes will be lost if you don't save them.": "Vos modifications seront perdues si vous ne les enregistrez pas.",
      "Don't save": "Ne pas enregistrer",
      "Saved": "Enregistré",
      "There are some places have the same place name": "Il y a quelques endroits ont le même nom de lieu",
      "The pathfinding '{{pathfindingName}}' has been saved": "Le pathfinding ' {{pathfindingName}}' a été enregistré",
      "New place": "Nouveau lieu",
      "Setted": "setted",
      "Select a Pathfinding file": "Sélectionnez un fichier Pathfinding",
      "Awesome pathfinding": "pathfinding impressionnant",
      "Place X": "lieu X",
      "Place Y": "lieu Y",
      "Place Name": "Nom Lieu"
    },
    "PathFindingItem.jsx": {
      "yours": "le tiens"
    },
    "PathFindingList.jsx": {
      "Select a Pathfinding file": "Sélectionnez un fichier Pathfinding",
      "Add": "Ajouter"
    },
    "ProjectCategories.jsx": {
      "Oops... no category found!": "Oops ... Aucune catégorie trouvée!",
      "Feel free to create one": "Ne hésitez pas à créer un",
      "Ask the admin to add one": "Demandez à l'administrateur d'ajouter un"
    },
    "ProjectCategoryItem.jsx": {
      "Are you sure?": "Êtes-vous sûr?",
      "You won't be able to revert this! {{categoryName}} will be lost.": "Vous ne serez pas en mesure de revenir ça! {{categoryName}} sera perdu.",
      "Yes, delete it!": "Oui, supprimez-le!",
      "No, cancel!": "Non, annuler!",
      "Error removing project": "Erreur projet suppression",
      "Removing": "Retrait",
      "Project category cover": "couverture Catégorie de projet",
      "{{number}} Projects": "{{number}} Projets"
    },
    "ProjectCard.jsx": {
      "Are you sure?": "Êtes-vous sûr?",
      "You won't be able to revert this! {{projectName}} will be lost.": "Vous ne serez pas en mesure de revenir ça! {{projectName}} sera perdu.",
      "Yes, delete it!": "Oui, supprimez-le!",
      "No, cancel!": "Non, annuler!",
      "Error removing project": "Erreur projet suppression",
      "Select a colour for this role": "Sélectionnez une couleur pour ce rôle",
      "You need to write something!": "Vous devez écrire quelque chose!",
      "Error on add role to the project project": "Erreur sur le rôle de complément au projet de projet",
      "Error on delete role": "Erreur sur le rôle de suppression",
      "Move {{projectName}} to category": "Bouge toi {{projectName}} à la catégorie",
      "Select the category": "Sélectionnez la catégorie",
      "Error on move to projects": "Erreur sur le mouvement des projets",
      "Project moved!": "Projet déplacé!",
      "The project was moved to new category.": "Le projet a été déplacé à la nouvelle catégorie.",
      "Removing ...": "Suppression ...",
      "Access: This project only": "Accès: Ce projet ne",
      "Access: All projects": "Accès: Tous les projets",
      "created by": "créé par",
      "Save": "sauver",
      "Create a new role": "Créer un nouveau rôle",
      "Move to category": "Déplacer à la catégorie"
    },
    "ProjectList.jsx": {
      "Oops... no projects found!": "Oups ... aucun projet trouvé!",
      "Feel free to create one": "Ne hésitez pas à créer un",
      "All categories": "Toutes catégories"
    },
    "SensorExcel.jsx": {
      "Robot #{{robotId}} Sensors": "# Robot {{robotId}} capteurs",
      "Robot #{{robotId}} Positions": "# Robot {{robotId}} positions",
      "Robot #{{robotId}} Battery": "# Robot {{robotId}} Batterie",
      "Oops: There are no robots seen by the camera.": "Oops: Il n'y a pas de robots vus par la caméra.",
      "Switch on a robot and place on the mat.": "Commutateur sur un robot et déposer sur le tapis."
    },
    "SensorWidget.jsx": {
      'Does not have any data yet': 'N\'a pas encore de données',
    },
    "SensorWidgetForS15.jsx": {
      "Temperature": "Température",
      "Humidity": "Humidité"
    },
    "ShareEmailForm.jsx": {
      "Please check \"I'm not a robot\"": "S'il vous plaît vérifier « Je ne suis pas un robot »"
    },
    "StatusItemKaiBotsOfflineOnMat.jsx": {
      "Offline - on Mat": "Hors ligne - le Mat"
    },
    "StatusItemKaiBotsOnlineOffMat.jsx": {
      "Online - off Mat": "En ligne - off Mat"
    },
    "StatusItemKaiBotsOnlineOnMat.jsx": {
      "Online - on Mat": "En ligne - sur Mat"
    },
    "StatusItemKaisEyeAlignCamera.jsx": {
      "Kai's Eye": "Eye Kai",
      "Align Camera": "aligner la caméra"
    },
    "StatusItemKaisEyeCameraOffline.jsx": {
      "Camera Offline": "caméra hors ligne"
    },
    "StatusItemKaisEyeCameraOnline.jsx": {
      "Camera Online": "Appareil photo en ligne"
    },
    "StatusItemSensors.jsx": {
      "{{number}} Online": "{{number}} en ligne"
    },
    "CameraStatusIcon.jsx": {
      "The camera has been offline!": "L'appareil photo a été déconnecté!",
      "Camera is online": "L'appareil photo est en ligne",
      "Camera is offline": "L'appareil photo est déconnecté"
    },
    "EditProjectModal.jsx": {
      "Junior": "Junior",
      "Intermediate": "Intermédiaire",
      "Advance": "Avance",
      "Expert": "Expert",
      "Project saved!": "Projet sauvé!",
      "was saved.": "a été sauvé.",
      "Error on save new project": "Erreur sur Enregistrer nouveau projet",
      "Edit project - {{projectName}}": "projet Edition - {{projectName}}",
      "Name your project": "Nommez votre projet",
      "Category": "Catégorie",
      "Select category": "Choisir une catégorie",
      "Map": "Carte",
      "Select map": "Sélectionnez la carte",
      "Select grade": "catégorie Choisir",
      "Enter a short summary of what this project is about.": "Entrez un bref résumé de ce que ce projet est sur le point.",
      "Cancel": "Annuler",
      "Save": "sauver"
    },
    "Help.jsx": {
      "Student": "Étudiant",
      "Project Help": "Aide projet",
      "Teacher": "Prof"
    },
    "HelpEditor.jsx": {
      "Explain how to play this game...": "Expliquer comment jouer à ce jeu ...",
      "No content": "Pas de contenu"
    },
    "Mat.jsx": {
      "Out of camera view": "Hors de la vue de la caméra",
      "Ensure Kai's Eye app is running and it can view the entire mat. Don't cover the robot or mat QR markers.": "Assurez-vous de l'application Eye Kai est en cours d'exécution et il peut voir le tapis entier. Ne couvrez pas le robot ou tapis marqueurs de QR.",
      "The robot {{robotId}} has been online!": "Le robot {{robotId}} a été en ligne!",
      "The robot {{robotId}} has been offline!": "Le robot {{robotId}} a été déconnecté!",
      "student name": "nom d'étudiant"
    },
    "NewCategoryModal.jsx": {
      "Add new category": "Ajouter une nouvelle catégorie",
      "Category saved!": "Catégorie sauvé!",
      "{{fieldsName}} was saved.": "{{fieldsName}} a été sauvé.",
      "Oops! Error on save category": "Oops! Erreur sur la catégorie de sauvegarde",
      "Display on": "afficher sur",
      "Feature": "Fonctionnalité",
      "Community": "Communauté",
      "Awesome category": "catégorie impressionnante",
      "Category name": "Nom de catégorie",
      "Order": "Ordre",
      "Cover": "Couverture",
      "Cancel": "Annuler",
      "Add category": "Ajouter une catégorie"
    },
    "NewProjectModal.jsx": {
      "Project saved!": "Projet sauvé!",
      "{{fieldsName}} was saved.": "{{fieldsName}} a été sauvé.",
      "Oops! Error on save project": "Oops! Erreur sur le projet de sauvegarde",
      "Enter a short summary of what this project is about.": "Entrez un bref résumé de ce que ce projet est sur le point.",
      "Add project": "Ajouter un projet",
      "Kai's Projects": "Les projets de Kai",
      "Community Projects": "Des projets de communauté",
      "Junior": "Junior",
      "Intermediate": "Intermédiaire",
      "Advance": "Avance",
      "Expert": "Expert",
      "Add new project": "Ajouter un nouveau projet",
      "Select category": "Choisir une catégorie",
      "Select map": "Sélectionnez la carte",
      "Select grade": "catégorie Choisir",
      "Cancel": "Annuler",
      "Project name": "Nom du projet",
      "Map": "Carte",
      "Level": "Niveau",
      "Cover": "Couverture",
      "Description": "La description",
      "Category": "Catégorie"
    },
    "NewUserGuideOverlay.jsx": {
      "Code Blocks:": "Blocs de code:",
      "Here are all the blocks you will use to build your code.": "Voici tous les blocs que vous utiliserez pour construire votre code.",
      "Drag and drop these into the blue \"Run\" block to get started.": "Glissez et déposez ceux-ci dans le bleu bloc « Exécuter » pour commencer.",
      "Workspace:": "espace de travail:",
      "This is the project you are currently accessing.": "Tel est le projet que vous accédez actuellement.",
      "Projects:": "Projets:",
      "Click here to access all existing projects, or share your own!": "Cliquez ici pour accéder à tous les projets existants, ou partager vos propres!",
      "Views:": "vues:",
      "Access the Map, 3D view, text coding, console and help from here.": "Accédez à la carte, vue 3D, le codage de texte, la console et l'aide d'ici.",
      "Map:": "Carte:",
      "This is a real time view of your adventure mat, with bot positions and live sensor data.": "Ceci est une vue en temps réel de votre tapis d'aventure, avec des positions de bot et les données des capteurs en direct.",
      "3D View:": "Vue 3D:",
      "Click here to load a 3D virtual view of your adventure mat!": "Cliquez ici pour afficher une vue virtuelle 3D de votre tapis d'aventure!",
      "Code:": "Code:",
      "This tab shows you what your blocks look like in text coding form": "Cet onglet vous montre ce que vos blocs ressemblent sous forme de codage texte",
      "Console:": "Console:",
      "Use the console to log data and messages, or debug your code.": "Utilisez la console pour enregistrer des données et des messages ou déboguer votre code.",
      "Help:": "Aidez-moi:",
      "Lesson plans and guides for both teachers and students can be found here.": "Plans de cours et guides pour les enseignants et les élèves peuvent être trouvés ici.",
      "Let's get coding'": "Obtenons codage »",
      "Kai In A Spaceship": "Kai In A Spaceship"
    },
    "ProjectsModal.jsx": {
      "Ooops! Error on saving sensors": "Oups! Erreur sur les capteurs d'économie",
      "My Projects": "Mes projets",
      "Kai's Projects": "Les projets de Kai",
      "Community": "Communauté",
      "Add project": "Ajouter un projet",
      "Add category": "Ajouter une catégorie"
    },
    "StatusBar.jsx": {
      "No Robot Selected": "Aucun robot sélectionné",
      "Robot {{robotId}}": "Robot {{robotId}}"
    },
    "src/routes/ClassRoom/components/TopMenu.jsx": {
      "PROJECT: {{title}}": "PROJET: {{title}}",
      "TEMPORARY WORKSPACE:": "TEMPORAIRE Workspace:"
    },
    "ViewsHelpForTempWorkSpaceIntroductionOnStudent.jsx": {
      "Document saved.": "document enregistré."
    },
    "ViewsConsole.jsx": {
      "Use the Print block to log messages to the console.": "Utilisez le bloc d'impression pour enregistrer les messages à la console.",
      "CLEAR CONSOLE": "EFFACER CONSOLE",
      "TO THE LATEST": "AU DERNIER"
    },
    "ViewsMap.jsx": {
      "Open Path Finding Mode": "Ouvrir le chemin du mode Trouver",
      "Path Finding Mode is opened, click to close": "Chemin mode Finding est ouvert, cliquez pour fermer",
      "Export data to Microsoft Excel file": "Exporter des données vers fichier Microsoft Excel"
    },
    "ViewsMap3D.jsx": {
      "Click to open Kai's Clan Virtual Viewer or download it.": "Cliquez pour ouvrir clan Viewer Virtual Kai ou le télécharger.",
      "Kai's Clan Virtual Viewer is running in the new window.": "Clan Viewer virtuel Kai est en cours d'exécution dans la nouvelle fenêtre."
    },
    "Workspace.jsx": {
      "{{number}} Result(s)": "{{number}} Résultats",
      "No results found under NEWBIE": "Aucun résultat trouvé sous NEWBIE",
      "No results found under EXPERT": "Aucun résultat trouvé sous EXPERT",
      "Close Search Result": "Fermer Résultat de la recherche",
      "Close": "proche",
      "Delete unused blocks": "Supprimer les blocs inutilisés",
      "You will not be able to restore these unused blocks.": "Vous ne serez pas en mesure de restaurer ces blocs inutilisés.",
      "Yes": "Oui",
      "Cancel": "Annuler"
    },
    "blocks/definition.js": {
      "func:robotArgByRole": {
        "Please associate a robot to me": "S'il vous plaît associer un robot me"
      },
      "func:getBoldText": {
        "read": "lis",
        "set": "ensemble",
        "accurately": "avec précision",
        "play": "jouer",
        "reset": "réinitialiser",
        "start": "début"
      },
      "Blockly.Blocks.setConfig": {
        "(Student Read-Only)": "(Étudiant en lecture seule)",
        "Set classroom variable": "Set variable classe",
        "None variable created": "Aucun variable créé"
      },
      "Blockly.Blocks.getConfig": {
        "(Read-Only)": "(Lecture seulement)",
        "Classroom": "Salle de classe",
        "value": "valeur"
      },
      "Blockly.Blocks.set_classroom_variable": {
        "(Student Read-Only)": "(Étudiant en lecture seule)",
        "Set classroom variable": "Set variable classe",
        "None variable created": "Aucun variable créé"
      },
      "Blockly.Blocks.get_classroom_variable": {
        "(Read-Only)": "(Lecture seulement)",
        "Classroom": "Salle de classe",
        "value": "valeur"
      },
      "Blockly.Blocks.runProgram": {
        "Run": "Courir",
        "Loop": "Boucle",
        "Events": "Événements"
      },
      "Blockly.Blocks.motion_move__set_gear": {
        "Bot %1 set speed to %2": "Robot %1  vitesse de consigne à %2",
        "slow": "lent",
        "normal": "Ordinaire",
        "fast": "vite",
        "fastest": "le plus rapide"
      },
      "Blockly.Blocks.motion_move__move_forwards_distance": {
        "Bot %1 move forward %2 cm": "Robot %1  Avance %2 cm"
      },
      "Blockly.Blocks.motion_move__move_backwards_distance": {
        "Bot %1 move backward %2 cm": "Robot %1  recule %2 cm"
      },
      "Blockly.Blocks.motion_angle__turn_left": {
        "Bot %1 turn left by %2 degrees": "Robot %1  tourner à gauche par %2 degrés"
      },
      "Blockly.Blocks.motion_angle__turn_right": {
        "Bot %1 turn right by %2 degrees": "Robot %1  tourner à droite par %2 degrés"
      },
      "Blockly.Blocks.motion_angle__change_to": {
        "Bot %1 set angle to %2 degrees": "Robot %1  angle de jeu %2 degrés"
      },
      "Blockly.Blocks.motion_move__move_to_position_obj": {
        "Bot %1 move to %2": "Robot %1  déménager à %2"
      },
      "Blockly.Blocks.motion_move__stopXYObjWithDistance": {
        "Bot %1 move to %2, stop %3 cm away": "Robot %1  déménager à %2, Arrêtez %3 cm"
      },
      "Blockly.Blocks.sensing__targetXYObj": {
        "%1 position X: %2 Y: %3 cm": "%1  position X: %2 Y: %3 cm"
      },
      "Blockly.Blocks.sensing__robotXYAObj": {
        "%1 position of Bot %2": "%1  Position du robot %2"
      },
      "Blockly.Blocks.sensing__mapMarkXYObj": {
        "%1 position of map mark": "%1  Emplacement de la marque de carte"
      },
      "Blockly.Blocks.sensing__distanceOfPositionXYObj": {
        "distance (cm) between %1 and %2": "distance (cm) between %1  et %2"
      },
      "Blockly.Blocks.motion_move__go_to_place": {
        "Bot %1 navigate to %2": "Robot %1  aller vers %2"
      },
      "Blockly.Blocks.motion_move__go_to_place_with_input_name": {
        "Bot %1 navigate to %2": "Robot %1  aller vers %2"
      },
      "Blockly.Blocks.motion_move__go_to_XYPosition_by_the_pathfinding": {
        "Bot %1 navigate to %2": "Robot %1  aller vers %2"
      },
      "Blockly.Blocks.motion_move__move_to_XY_obj_with_self_adjustment": {
        "Bot %1 %2 move to %3": "Robot %1  %2 déménager à %3"
      },
      "Blockly.Blocks.motion_move__move_to_XY_obj_stop_distance_with_self_adjustment": {
        "Bot %1 %2 move to %3, stop at distance %4 cm": "Robot %1  %2 déménager à %3, Arrêt à la distance %4 cm"
      },
      "Blockly.Blocks.motion_move__grabObjAtXY": {
        "Bot %1 grab object at %2": "Robot %1  saisir l'objet à %2"
      },
      "Blockly.Blocks.motion_move__releaseObjAtXY": {
        "Bot %1 release object at %2": "Robot %1  libérer l'objet à %2"
      },
      "Blockly.Blocks.motion_follow__stopXYObjWithDistance": {
        "Bot %1 follow %2, go %3 cm": "Robot %1  suivre %2, aller %3 cm"
      },
      "Blockly.Blocks.motion_move__stop": {
        "Bot %1 stop moving (Event)": "Robot %1  arrêter le déplacement (Event)"
      },
      "Blockly.Blocks.motion_special__circle": {
        "Bot %1 drive %2 in circle, diameter %3cm, central angles %4°": "Robot %1  conduire %2 dans le cercle, le diamètre %3cm, angles centraux %4°",
        "left": "la gauche",
        "right": "droite"
      },
      "Blockly.Blocks.motion_servo__open_close_toggle": {
        "Bot %1 %2 ⛵ Gripper open-status %3": "Robot %1  %2 ⛵ Gripper ouvert statut %3"
      },
      "Blockly.Blocks.sensing__gripper": {
        "Bot %1 %2 ⛵ Gripper open-status (true/false)": "Robot %1  %2 ⛵ ouvert état Gripper (vrai / faux)"
      },
      "Blockly.Blocks.sensing__ultrasonic_distance_sensor": {
        "Bot %1 %2 ⛵ Ultrasonic Sensor distance (mm)": "Robot %1  %2 ⛵ ultrasons capteur de distance (mm)"
      },
      "Blockly.Blocks.sound_board_buzzer_play__sound": {
        "Bot %1 ⛵ Buzzer %2 %3 at %4 %% volume": "Robot %1  ⛵ Buzzer %2 %3 à %4 %% le volume"
      },
      "Blockly.Blocks.leddiffuser_set_colorObj": {
        "Bot %1 %2 ⛵ %3 Headlight(s) to %4": "Robot %1  %2 ⛵ %3 Phare (s) à %4",
        "Left": "La gauche",
        "Right": "Droite",
        "Both": "Roboth"
      },
      "Blockly.Blocks.leddiffuser_blink": {
        "Bot %1 %2 ⛵ Headlights blink every %3 ms, duty %4 %%, brightness %5 %%": "Robot %1  %2 ⛵ phares clignotent toutes les %3 ms, devoir %4 %%, la luminosité %5 %%"
      },
      "Blockly.Blocks.leddiffuser_blink_swap": {
        "Bot %1 %2 ⛵ Headlights swap color %3": "Robot %1  %2 couleur d'échange ⛵ Phares %3"
      },
      "Blockly.Blocks.sound_ext_buzzer_play__sound": {
        "Bot %1 🔌Add-on Buzzer %2 %3 at %4 %% volume": "Robot %1  🔌Add sur Buzzer %2 %3 à %4 %% le volume"
      },
      "Blockly.Blocks.sensing__soundRTTTLListObj": {
        "%1 Song %2": "%1  Chanson %2"
      },
      "Blockly.Blocks.sensing__soundRTTTLCommandObj": {
        "%1 RTTTL %2 %3": "%1  RTTTL %2 %3",
        "RTTTL rhythm is too long !": "RTTTL rythme est trop long!"
      },
      "Blockly.Blocks.sensing__soundBeepObj": {
        "%1 Buzz, frequency %2 Hz, duration %3 ms": "%1  Bourdonnement, fréquence %2 Hz, la durée %3 Mme"
      },
      "Blockly.Blocks.sensing__Sound_Sensor": {
        "Bot %1 %2 🔌Add-on Sound Sensor sound level (%)": "Robot %1  %2 🔌Add sur le niveau sonore de son capteur (%)"
      },
      "Blockly.Blocks.sensing__colorPickerObj": {
        "%1 color %2": "%1  Couleur %2"
      },
      "Blockly.Blocks.sensing__colorRGBObj": {
        "%1 color Red %2 Green %3 Blue %4": "%1  La couleur rouge %2 vert %3 Bleu %4"
      },
      "Blockly.Blocks.sensing__Color_Sensor": {
        "%1 Bot %2 %3 🔌Add-on Color Sensor": "%1  Robot %2 %3 🔌Add sur Capteur de couleur"
      },
      "Blockly.Blocks.sight_led_white__adjust_brightness": {
        "Bot %1 %2 🔌Add-on Single LED brightness to %3 %%": "Robot %1  %2 🔌Add-on luminosité de la DEL unique à %3 %%"
      },
      "Blockly.Blocks.sight_led_white__blink": {
        "Bot %1 %2 🔌Add-on Single LED blink %3 times every 5s": "Robot %1  %2 🔌Add sur l'unité LED clignote %3 fois tous les 5 s"
      },
      "Blockly.Blocks.sensing__Bright_LED": {
        "Bot %1 %2 🔌Add-on Single LED brightness (%%)": "Robot %1  %2 🔌Add-on luminosité LED unique (%%)"
      },
      "Blockly.Blocks.sight_led_strip__adjust_all_color": {
        "Bot %1 %2 🔌Add-on RGB-LED color at all positions to %3": "Robot %1  %2 🔌Add-sur-LED couleur RVB dans toutes les positions à %3"
      },
      "Blockly.Blocks.sight_led_strip__adjust_index_color": {
        "Bot %1 %2 🔌Add-on RGB-LED color at position %3 to %4": "Robot %1  %2 🔌Add-sur-LED couleur RVB en position %3 à %4"
      },
      "Blockly.Blocks.sight_led_strip__adjust_all_brightness": {
        "Bot %1 %2 🔌Add-on RGB-LED all lights brightness to %3 %%": "Robot %1  %2 🔌Add sur la luminosité RGB-LED tous les feux de %3 %%"
      },
      "Blockly.Blocks.sight_led_strip__adjust_index_brightness": {
        "Bot %1 %2 🔌Add-on RGB-LED light at position %3 , brightness to %4 %%": "Robot %1  %2 🔌Add sur la lumière LED RGB en position %3 , La luminosité de %4 %%"
      },
      "Blockly.Blocks.sight_led_strip__light_mode": {
        "Bot %1 %2 🔌Add-on RGB-LED %3, animate %4 times every 5s": "Robot %1  %2 🔌Add-sur-LED RGB %3, animer %4 fois tous les 5 s",
        "right scroll": "Faire défiler à droite",
        "left scroll": "défilement gauche",
        "mirror flip": "flip miroir",
        "on/off blink": "on / off blink"
      },
      "Blockly.Blocks.sight_led_strip__reset": {
        "Bot %1 %2 🔌Add-on RGB-LED to default": "Robot %1  %2 🔌Add-sur-LED RGB par défaut"
      },
      "Blockly.Blocks.sensing__led_strip__read_rgb_by_index_number": {
        "%1 Bot %2 %3 🔌Add-on RGB-LED color at position %4": "%1  Robot %2 %3 🔌Add-sur-LED couleur RVB en position %4"
      },
      "Blockly.Blocks.sight_4digi_tube__display_all_4digi": {
        "Bot %1 %2 🔌Add-on 4-Digit Display to %3 %4 : %5 %6": "Robot %1  %2 🔌Add sur affichage à 4 chiffres %3 %4 : %5 %6"
      },
      "Blockly.Blocks.sight_4digi_tube__display_index_4digi": {
        "Bot %1 %2 🔌Add-on 4-Digit Display number at position %3 to %4": "Robot %1  %2 🔌Add sur Afficher le numéro à 4 chiffres à la position %3 à %4"
      },
      "Blockly.Blocks.sight_4digi_tube__display_time": {
        "Bot %1 %2 🔌Add-on 4-Digit Display to MM:SS %3 : %4": "Robot %1  %2 🔌Add sur affichage à 4 chiffres à MM: SS %3 : %4"
      },
      "Blockly.Blocks.sight_4digi_tube__display_time_countdown": {
        "Bot %1 %2 🔌Add-on 4-Digit Display to MM %3 : SS %4 and start to countdown ⏰": "Robot %1  %2 🔌Add sur affichage à 4 chiffres à MM %3 : SS %4 et commencer le compte à rebours ⏰"
      },
      "Blockly.Blocks.sight_4digi_tube__enable_index": {
        "Bot %1 %2 🔌Add-on 4-Digit Display enable-status at position %3 to %4": "Robot %1  %2 🔌Add sur 4 chiffres d'affichage permettent d'état à la position %3 à %4"
      },
      "Blockly.Blocks.sight_4digi_tube__set_brightness": {
        "Bot %1 %2 🔌Add-on 4-Digit Display brightness to %3": "Robot %1  %2 🔌Add sur la luminosité de l'afficheur à 4 chiffres à %3"
      },
      "Blockly.Blocks.sensing__The_4_Digit_Display": {
        "Bot %1 %2 🔌Add-on 4-Digit Display value at position %3": "Robot %1  %2 🔌Add sur 4 chiffres Valeur d'affichage à la position %3"
      },
      "Blockly.Blocks.sight_led_matrix_display__show": {
        "Bot %1 %2 🔌Add-on LED Matrix to display %3": "Robot %1  %2 🔌Add sur Matrice DEL pour afficher %3"
      },
      "Blockly.Blocks.sight_led_matrix_display__set_text": {
        "Bot %1 %2 🔌Add-on LED Matrix, display %3": "Robot %1  %2 🔌Add sur Matrice DEL, affichage %3"
      },
      "Blockly.Blocks.sight_led_matrix_display__set_progress": {
        "Bot %1 %2 🔌Add-on LED Matrix, to display progress bar of %3 %%": "Robot %1  %2 🔌Add sur LED Matrix, pour afficher la barre de progression de %3 %%"
      },
      "Blockly.Blocks.sight_led_matrix_display__set_brightness": {
        "Bot %1 %2 🔌Add-on LED Matrix brightness to %3": "Robot %1  %2 🔌Add sur la luminosité LED Matrice de %3"
      },
      "Blockly.Blocks.sensing__led_matrix_display": {
        "░ Bot %1 %2 🔌Add-on LED Matrix pixels": "░ Robot %1  %2 🔌Add-pixels sur la matrice LED"
      },
      "Blockly.Blocks.sensing__led_matrix_display_constant": {
        "░ 8x8 matrix of %1": "░ 8x8 matrix of %1"
      },
      "Blockly.Blocks.sensing__led_matrix_display_pixels": {
        "LED matrix pixels:": "pixels de la matrice de LED:"
      },
      "Blockly.Blocks.sensing__Light_Sensor": {
        "Bot %1 %2 🔌Add-on Light Sensor (%)": "Robot %1  %2 🔌Add-on capteur de lumière (%)"
      },
      "Blockly.Blocks.sensing__humidity_sensor": {
        "Bot %1 %2 🔌Add-on Temperature & Humidity Sensor %3": "Robot %1  %2 🔌Add sur capteur de température et d'humidité %3",
        "Temperature (℃)": "Température (℃)",
        "Humidity (%)": "Humidité (%)"
      },
      "Blockly.Blocks.sensing__UV_Sensor": {
        "Bot %1 %2 🔌Add-on UV Sensor (%)": "Robot %1  %2 🔌Add-on capteur UV (%)"
      },
      "Blockly.Blocks.sensing__Moisture_Sensor": {
        "Bot %1 %2 🔌Add-on Moisture Sensor (%)": "Robot %1  %2 🔌Add-on capteur d'humidité (%)"
      },
      "Blockly.Blocks.sensing__Water_Sensor": {
        "Bot %1 %2 🔌Add-on Water Sensor (%)": "Robot %1  %2 🔌Add sur capteur d'eau (%)"
      },
      "Blockly.Blocks.sensing__Waterproof_Temperature_Sensor": {
        "Bot %1 %2 🔌Add-on Waterproof Temperature Sensor (℃)": "Robot %1  %2 🔌Add sur la sonde de température étanche (℃)"
      },
      "Blockly.Blocks.sensing__PH_Sensor": {
        "Bot %1 %2 🔌Add-on PH Sensor (pH value)": "Robot %1  %2 🔌Add-on capteur de pH (pH)"
      },
      "Blockly.Blocks.sensing__BMP180_Barometer": {
        "Bot %1 %2 🔌Add-on BMP180 Barometer (kPa)": "Robot %1  %2 🔌Add sur Baromètre BMP180 (kPa)"
      },
      "Blockly.Blocks.sensing__BME280_Atmospheric_Sensor": {
        "Bot %1 %2 🔌Add-on Atmospheric Sensor %3": "Robot %1  %2 🔌Add sur l'atmosphère du capteur %3",
        "air pressure(kPa)": "pression atmosphérique (kPa)",
        "humidity (%)": "humidité (%)",
        "temperature (℃)": "température (℃)"
      },
      "Blockly.Blocks.irTransmitter": {
        "Bot %1 %2 🔌Add-on IR Emitter to send %3": "Robot %1  %2 🔌Add sur l'émetteur IR envoyer %3"
      },
      "Blockly.Blocks.sensing__irReceiver": {
        "📡 %1 %2 🔌Add-on IR Receiver lasted raw code": "📡 %1  %2 🔌Add sur le récepteur IR a duré code brut"
      },
      "Blockly.Blocks.sensing__irRawCode": {
        "📡 IR remote raw code %1": "📡 IR remote raw code %1"
      },
      "Blockly.Blocks.relaySwitch": {
        "Bot %1 %2 🔌Add-on Relay on-status %3": "Robot %1  %2 🔌Add sur relais en état %3"
      },
      "Blockly.Blocks.sensing__relay": {
        "Bot %1 %2 🔌Add-on Relay on-status (true/false)": "Robot %1  %2 🔌Add sur relais en état (vrai / faux)"
      },
      "Blockly.Blocks.sensing__Rotary_Angel_Sensor": {
        "Bot %1 %2 🔌Add-on Rotary Angel Sensor (degree)": "Robot %1  %2 🔌Add-on capteur rotatif Angel (degré)"
      },
      "Blockly.Blocks.sensing__Linear_Potentiometer": {
        "Bot %1 %2 🔌Add-on Linear Potentiometer (%)": "Robot %1  %2 🔌Add sur potentiomètre linéaire (%)"
      },
      "Blockly.Blocks.Sensor_Pulse_Sensor_enable": {
        "Bot %1 %2 🔌Add-on Pulse Sensor Detection": "Robot %1  %2 🔌Add sur détection d'impulsion du capteur"
      },
      "Blockly.Blocks.Sensor_Photo_Electric_Counter_reset": {
        "Bot %1 %2 🔌Add-on Photo Electric Counter": "Robot %1  %2 🔌Add sur photo électrique contre"
      },
      "Blockly.Blocks.sensing__Photo_Electric_Counter": {
        "Bot %1 %2 🔌Add-on Photo Electric Counter": "Robot %1  %2 🔌Add sur photo électrique contre"
      },
      "Blockly.Blocks.sensing__Touch_Sensor": {
        "Bot %1 %2 🔌Add-on Touch Sensor touch-status (true/false)": "Robot %1  %2 🔌Add-touch statut capteur tactile (true / false)"
      },
      "Blockly.Blocks.sensing__Push_Button": {
        "Bot %1 %2 🔌Add-on Button pressed-status (true/false)": "Robot %1  %2 🔌Add sur le bouton d'état pressé (vrai / faux)"
      },
      "Blockly.Blocks.sensing__Switch": {
        "Bot %1 %2 🔌Add-on Switch on-status (true/false)": "Robot %1  %2 🔌Add gachette en état (vrai / faux)"
      },
      "Blockly.Blocks.sensing__ir_reflective_sensor": {
        "Bot %1 %2 🔌Add-on IR Reflective Sensor detected-status (true/false)": "Robot %1  %2 🔌Add sur capteur IR réfléchissant-état détecté (vrai / faux)"
      },
      "Blockly.Blocks.sensing__limit_switch": {
        "Bot %1 %2 🔌Add-on Collision Sensor closed-status (true/false)": "Robot %1  %2 🔌Add collision frontale du capteur d'état fermé (vrai / faux)"
      },
      "Blockly.Blocks.sensing__tilt_switch": {
        "Bot %1 %2 🔌Add-on Tilt Switch tilt-status (true/false)": "Robot %1  %2 🔌Add-tilt-status Tilt Switch (vrai / faux)"
      },
      "Blockly.Blocks.sensing__Pulse_Sensor": {
        "Bot %1 %2 🔌Add-on Pulse Sensor heart beat rates (per min)": "Robot %1  %2 🔌Add-sur les taux de pouls rythme cardiaque capteur (par minute)"
      },
      "Blockly.Blocks.sensing__human_body_ir_sensor": {
        "Bot %1 %2 🔌Add-on PIR Motion Sensor active-status (true/false)": "Robot %1  %2 🔌Add sur PIR Motion Sensor-état actif (vrai / faux)"
      },
      "Blockly.Blocks.sensing__Laser_Ranging_Sensor": {
        "Bot %1 %2 🔌Add-on Laser Ranging Sensor distance (mm)": "Robot %1  %2 🔌Add-on Laser Ranging la distance du capteur (mm)"
      },
      "Blockly.Blocks.sensing__read_3Axis_Digital_Compass_by_xyz": {
        "Bot %1 %2 🔌Add-on 3-Axis Digital Compass %3 gauss": "Robot %1  %2 🔌Add sur 3 axes Boussole numérique %3 gauss"
      },
      "Blockly.Blocks.sensing__MPU6050_Accelerometer_and_Gyro": {
        "Bot %1 %2 🔌Add-on MPU6050 Accelerometer & Gyro %3": "Robot %1  %2 🔌Add sur MPU6050 Accéléromètre & Gyro %3",
        "X Acceleration": "Accélération X",
        "Y Acceleration": "Accélération Y",
        "Z Acceleration": "Z Accélération",
        "X Gravity": "Gravity X",
        "Y Gravity": "Y Gravity",
        "Z Gravity": "Z Gravity"
      },
      "Blockly.Blocks.events__irReceiver_Received": {
        "On bot %1 🔌Add-on IR Reciever triggered with IR-code %2": "ten boats%1  🔌Add sur IR Reciever déclenché avec le code IR %2"
      },
      "Blockly.Blocks.events__touch_sensor_touching": {
        "On bot %1 🔌Add-on Touch Sensor touching": "ten boats%1  🔌Add sur toucher capteur tactile"
      },
      "Blockly.Blocks.events__button_pushed": {
        "On bot %1 🔌Add-on Button pressed": "ten boats%1  🔌Add sur le bouton pressé"
      },
      "Blockly.Blocks.events__switch_on": {
        "On bot %1 🔌Add-on Switch on": "ten boats%1  🔌Add-on Enclencher"
      },
      "Blockly.Blocks.events__switch_off": {
        "On bot %1 🔌Add-on Switch off": "ten boats%1  🔌Add-Éteindre"
      },
      "Blockly.Blocks.events__ir_reflective_sensor": {
        "On bot %1 🔌Add-on IR Reflective Sensor object detected": "ten boats%1  🔌Add sur l'objet réfléchissant capteur IR détecté"
      },
      "Blockly.Blocks.events__limit_switch": {
        "On bot %1 🔌Add-on Collision Sensor closed": "ten boats%1  🔌Add-on capteur de collision fermé"
      },
      "Blockly.Blocks.events__tilt_detected": {
        "On bot %1 🔌Add-on Tilt Switch tilt detected": "ten boats%1  🔌Add-tilt Tilt Switch détecté"
      },
      "Blockly.Blocks.events__human_body_ir_sensor": {
        "On bot %1 🔌Add-on PIR Motion Sensor active": "ten boats%1  🔌Add sur PIR détecteur de mouvement actif"
      },
      "Blockly.Blocks.events__magnetic_field_detected": {
        "On bot %1 🔌Add-on Hall Sensor active": "ten boats%1  🔌Add-Hall: capteur actif"
      },
      "Blockly.Blocks.events__vibration_detected": {
        "On bot %1 🔌Add-on Vibration Sensor vibration detected": "ten boats%1  🔌Add sur vibration Capteur de vibration détectée"
      },
      "Blockly.Blocks.events__knock_detected": {
        "On bot %1 🔌Add-on Vibration Sensor knock detected": "ten boats%1  🔌Add-on capteur de vibrations détecté knock"
      },
      "Blockly.Blocks.events__pulse_sensor_detected": {
        "On bot %1 🔌Add-on Pulse Sensor detecting finished": "ten boats%1  🔌Add-on capteur d'impulsion de détection fini"
      },
      "Blockly.Blocks.events__gesture_detected": {
        "On bot %1 🔌Add-on Gesture detected with gesture %2": "ten boats%1  🔌Add sur le geste détecté avec un geste %2"
      },
      "Blockly.Blocks.events__out_of_boundary": {
        "On bot %1 out of mat boundary": "ten boats%1  de limite de mat"
      },
      "Blockly.Blocks.control__wait": {
        "%1 wait %2 second(s)": "%1  attendez %2 deuxième (s)"
      },
      "Blockly.Blocks.sensing__value_of_datetime": {
        "current %1": "current %1",
        "Year": "Année",
        "Month": "Mois",
        "Day": "journée",
        "Hour": "Heure",
        "Minute": "Minute",
        "Second": "Seconde"
      },
      "Blockly.Blocks.sensing__current_second": {
        "current system time (seconds)": "heure système actuelle (secondes)"
      },
      "Blockly.Blocks.sensing__current_millisecond": {
        "Current system time (milliseconds)": "Heure actuelle du système (millisecondes)"
      },
      "Blockly.Blocks.log": {
        "%1 print %2 to log": "%1  impression %2 se connecter"
      },
      "Blockly.Blocks.customized_logic_totext": {
        "%1 to Text": "%1  écrire un SMS"
      },
      "Blockly.Blocks.customized_controls_if": {
        "%{BKY_CONTROLS_IF_MSG_IF} %1": "%{BKY_CONTROLS_IF_MSG_IF} %1",
        "%{BKY_CONTROLS_IF_MSG_THEN} %1": "%{BKY_CONTROLS_IF_MSG_THEN} %1"
      },
      "Blockly.Blocks.customized_logic_compare": {
        "%1 %2 %3": "%1  %2 %3"
      },
      "Blockly.Blocks.sensing__objCompare": {
        "❔ object %1 equals to %2": "❔ object %1  égale à %2"
      },
      "Blockly.Blocks.output_logic__range": {
        "is %1 %2 Min. %3 Max. %4": "is %1  %2 Min. %3 Max. %4",
        "inside": "à l'intérieur",
        "outside": "à l'extérieur"
      },
      "Blockly.Blocks.sensing__constant_angle": {
        "angle %1": "angle %1"
      },
      "Blockly.Blocks.customized_logic_tonumber": {
        "%1 to Number": "%1  Number"
      },
      "Blockly.Blocks.read__dice_value": {
        "%1 number of 🎲dice": "%1  nombre de 🎲dice",
        "read": "lis"
      },
      "Blockly.Blocks.array_declare": {
        "⛓ Declare an Array named:": "⛓ Déclarer un tableau nommé:"
      },
      "Blockly.Blocks.array_set_item": {
        "Set element: %1 to value: %2 in array: %3": "Set element: %1  évaluer: %2 dans le tableau: %3"
      },
      "Blockly.Blocks.array_get_item": {
        "Get element %1 from array variable: %2": "Get element %1  de variable de tableau: %2"
      },
      "Blockly.Blocks.array_length": {
        "Get array %1 length": "Get array %1  longueur"
      },
      "Blockly.Blocks.the3d__object_effect_onFire_frozen": {
        "🎆 Sprite %1 perform %2": "🎆 Sprite %1  effectuer %2"
      },
      "Blockly.Blocks.the3d__object_effect_nil_effect": {
        "🎆 Sprite %1 perform effect": "🎆 Sprite %1  effectuer effet"
      },
      "Blockly.Blocks.the3d__object_effect_flying_height": {
        "🎆 Flying Sprite  %1 set radius to %2": "🎆 Flying Sprite  %1  rayon fixé à %2"
      },
      "Blockly.Blocks.motion_angle__turn": {
        "Bot %1 turn %2": "Robot %1  tour %2",
        "left": "la gauche",
        "right": "droite",
        "slight left": "légèrement à gauche",
        "slight right": "légèrement à droite",
        "left u-turn": "gauche u-turn",
        "right u-turn": "droite demi-tour"
      },
      "Blockly.Blocks.motion_servo__beginner__open_close_toggle": {
        "Bot %1 %2 gripper": "Robot %1  %2 pince",
        "open": "ouvert",
        "close": "proche"
      },
      "Blockly.Blocks.motion_angle__look": {
        "Bot %1 look %2": "Robot %1  Regardez %2",
        "Up/North": "Up / Nord",
        "Right/East": "Droite / Est",
        "Down/South": "Bas / Sud",
        "Left/West": "Gauche / Ouest"
      },
      "Blockly.Blocks.motion_move__move_to_XY": {
        "Bot %1 move to X: %2 Y: %3 cm": "Robot %1  passer à X: %2 Y: %3 cm"
      },
      "Blockly.Blocks.sensing__robotXYA": {
        "read %1 of Bot %2": "read %1  Robot %2",
        "x (cm)": "x (cm)",
        "y (cm)": "y (cm)",
        "angle (°)": "angle (°)"
      },
      "Blockly.Blocks.beginner__motion_mirror__follow": {
        "Bot %1 follow %2": "Robot %1  suivre %2"
      },
      "Blockly.Blocks.motion_special__grab_object": {
        "Bot %1 grab object at X: %2 cm Y: %3 cm": "Robot %1  saisir l'objet à X: %2 cm Y: %3 cm"
      },
      "Blockly.Blocks.motion_special__release_object": {
        "Bot %1 release object at X: %2 cm Y: %3 cm": "Robot %1  libérer l'objet à X: %2 cm Y: %3 cm"
      },
      "Blockly.Blocks.beginner__motion_special__drive_in_circle": {
        "Bot %1 drive %2 in %3 circle": "Robot %1  conduire %2 dans %3 cercle",
        "left": "la gauche",
        "right": "droite",
        "Small": "Petit",
        "Medium": "Moyen",
        "Large": "Grand"
      },
      "Blockly.Blocks.beginner__play_song": {
        "Bot %1 play song %2": "Robot %1  jouer la chanson %2"
      },
      "Blockly.Blocks.leddiffuser_set_on_state": {
        "Bot %1 set Headlight on %2": "Robot %1  mis sur phares %2"
      },
      "Blockly.Blocks.leddiffuser_set_color": {
        "Bot %1 set Headlight color to %2": "Robot %1  définir la couleur phare de %2"
      },
      "Blockly.Blocks.beginner__LED_turn_onOff": {
        "Bot %1 🔌Add-on LED %2": "Robot %1  🔌Add-on LED %2",
        "On": "Sur",
        "Off": "De"
      },
      "Blockly.Blocks.sight_led_strip_4_colors": {
        "Bot %1 🔌Add-on RGB-LED %2 %3 %4 %5": "Robot %1  🔌Add-sur-LED RGB %2 %3 %4 %5"
      },
      "Blockly.Blocks.beginner__sight_led_matrix_display__show": {
        "Bot %1 🔌Add-on LED Matrix, display symbol %2": "Robot %1  🔌Add sur LED Matrix, symbole d'affichage %2"
      },
      "Blockly.Blocks.beginner__sight_led_matrix_display__set_text": {
        "Bot %1 🔌Add-on LED Matrix, display text %2": "Robot %1  🔌Add sur LED Matrix, texte d'affichage %2"
      },
      "Blockly.Blocks.beginner__events__button_pushed": {
        "When bot %1 button pushed": "When bot %1  bouton poussé"
      },
      "Blockly.Blocks.beginner__events__out_of_boundary": {
        "When bot %1 drives off mat": "When bot %1  démarre mat"
      },
      "Blockly.Blocks.beginner__sensing__ultrasonic_distance_sensor": {
        "Bot %1 %2 Distance Sensor": "Robot %1  %2 Distance du capteur"
      },
      "func:getSyncModeArg": {
        "synchronously": "synchrone",
        "asynchronously": "de manière asynchrone"
      },
      "Blockly.Blocks.motion_move__park": {
        "Bot %1 park in slot %2 %3": "Robot %1  parc à fente %2 %3"
      },
      "Blockly.Blocks.sensing__mapMarkXY": {
        "read %1 of map mark position (cm)": "read %1  la position de la marque de la carte (cm)"
      },
      "Blockly.Blocks.motion_move__move_to_XY_with_self_adjustment": {
        "Bot %1 move to X: %2 Y: %3 cm ⇨ with accurate adjustments": "Robot %1  passer à X: %2 Y: %3 cm ⇨ avec des réglages précis"
      },
      "Blockly.Blocks.motion_move__move_to_XY_stop_distance_with_self_adjustment": {
        "Bot %1 move to X: %2 Y: %3 cm ⇨ stop at distance %4 cm ⇨ with accurate adjustments": "Robot %1  passer à X: %2 Y: %3 cm ⇨ butée à une distance %4 cm ⇨ avec des réglages précis"
      },
      "Blockly.Blocks.motion_mirror__follow": {
        "Bot %1 follow bot %2 by %3 cm away": "Robot %1  suivez bot %2 par %3 cm"
      },
      "Blockly.Blocks.motion_special__grab_object_by_halfway": {
        "Bot %1 grab object at X: %2 cm Y: %3 cm by Halfway": "Robot %1  saisir l'objet à X: %2 cm Y: %3 A mi-chemin par cm"
      },
      "Blockly.Blocks.motion_special__oval": {
        "Bot %1 oval (diameter X %2 cm Y %3 cm) %4": "Robot %1  ovale (diamètre X %2 cm Y %3 cm) %4",
        "Left": "La gauche",
        "Right": "Droite"
      },
      "Blockly.Blocks.beginner__headlights_set_colorObj": {
        "Bot %1 Headlights %2": "Robot %1  phares %2"
      },
      "Blockly.Blocks.sensing__led__read_whether_on": {
        "Bot %1 %2 🔌Add-on LED On-status (Boolean)": "Robot %1  %2 -🔌Add témoin On-état (booléen)"
      },
      "Blockly.Blocks.sight_led__switch": {
        "Bot %1 %2 🔌Add-on LED On-status %3": "Robot %1  %2 🔌Add sur Allumée statut %3"
      },
      "Blockly.Blocks.sensing__rgb_color": {
        "Bot %1 read RGB-LED color at position %2": "Robot %1  lire la couleur RGB-LED à la position %2"
      },
      "Blockly.Blocks.sensing__tube_display": {
        "Bot %1 read 4digit display %2": "Robot %1  lire l'affichage 4 chiffres %2",
        "minutes": "minutes",
        "seconds": "secondes"
      },
      "Blockly.Blocks.sight_led__color": {
        "LED Colour: %1": "LED Colour: %1"
      },
      "Blockly.Blocks.sight_led_strip__light": {
        "Bot %1 RGB-LED position #%2 by intensity %3 %% color %4": "Robot %1  RGB-LED position ##%2 par l'intensité %3 %% Couleur %4"
      },
      "Blockly.Blocks.sight_led_strip__light_off": {
        "Bot %1 RGB-LED position %2 off": "Robot %1  RGB-LED situation %2 de"
      },
      "Blockly.Blocks.sight_tube_display__show": {
        "Bot %1 show %2:%3 on 4digit display": "Robot %1  spectacle %2:%3 sur l'affichage 4 chiffres"
      },
      "Blockly.Blocks.sendCommand": {
        "%1 Send %2 command %3 with params %4": "%1  Envoyer %2 commander %3 avec params %4"
      },
      "Blockly.Blocks.is_robot_position_in_the_area": {
        "is Bot %1 at %2 within %3 CM": "is Robot %1  à %2 dans %3 CM"
      }
    },
    "studentToolbox": {
      "advanced.xml": {
        "Motion 🚗": "Mouvement 🚗",
        "Free Movement": "Mouvement libre",
        "Mat Movement 🏃": "Mat Mouvement 🏃",
        "AI Navigation 💡": "AI Navigation 💡",
        "Position adjustment 🚐": "Réglage de la position 🚐",
        "Interactions 🤝": "interactions 🤝",
        "Special": "Spécial",
        "Bits 💡": "Morceaux 💡",
        "On-board ⛵": "Bord ⛵",
        "Sound 🙊": "son 🙊",
        "Sight 🙈": "vue 🙈",
        "Environmental ⛅": "⛅ environnement",
        "Action 🍭": "action 🍭",
        "3D Effects 🎆": "Effets 3D 🎆",
        "Events 🎈": "événements 🎈",
        "Sensor Events": "Événements capteur",
        "System Events": "Événements système",
        "Timing ⌛️": "Timing ⌛️",
        "Print 📝": "Impression 📝",
        "Logic 🤔": "Logic 🤔",
        "Loops 💈": "boucles 💈",
        "Functions 💻": "fonctions 💻",
        "Math 🤯": "Mathématiques 🤯",
        "Arrays ⛓": "Les tableaux ⛓",
        "Variables 🤹": "Variables 🤹",
        "💐 Local variables": "💐 variables locales",
        "🏫 Classroom variables": "🏫 variables en classe",
        "Create variable...": "Créer une variable ...",
        "RGB Color": "Couleur RVB",
        "Color Sensor": "Capteur de couleur",
        "Single LED": "LED unique",
        "RGB-LED": "RGB-LED",
        "4-digit Display": "Affichage à 4 chiffres",
        "8x8 LED Matrix": "Matrice DEL 8x8"
      },
      "beginner.xml": {
        "Motion 🚗": "Mouvement 🚗",
        "Free Movement": "Mouvement libre",
        "Mat Movement 🏃": "mat Mouvement 🏃",
        "Special": "Spécial",
        "Bits 💡": "Morceaux 💡",
        "Sound 🙊": "Du son 🙊",
        "Sight 🙈": "Vue 🙈",
        "Events 🎈": "événements 🎈",
        "Timing ⌛️": "Timing ⌛️",
        "Print 📝": "Impression 📝",
        "Loops 💈": "boucles 💈",
        "Math 🤯": "Math 🤯",
        "Sensing 📐": "détection 📐"
      }
    },
    "teacherToolbox": {
      "advanced.xml": {
        "Motion 🚗": "Mouvement 🚗",
        "Free Movement": "Mouvement libre",
        "Mat Movement 🏃": "Mat Mouvement 🏃",
        "AI Navigation 💡": "AI Navigation 💡",
        "Position adjustment 🚐": "Réglage de la position 🚐",
        "Interactions 🤝": "interactions 🤝",
        "Special": "Spécial",
        "Bits 💡": "Morceaux 💡",
        "On-board ⛵": "Bord ⛵",
        "Sound 🙊": "son 🙊",
        "Sight 🙈": "vue 🙈",
        "Environmental ⛅": "⛅ environnement",
        "Action 🍭": "action 🍭",
        "3D Effects 🎆": "Effets 3D 🎆",
        "Events 🎈": "événements 🎈",
        "Sensor Events": "Événements capteur",
        "System Events": "Événements système",
        "Timing ⌛️": "Timing ⌛️",
        "Print 📝": "Impression 📝",
        "Logic 🤔": "Logic 🤔",
        "Loops 💈": "boucles 💈",
        "Functions 💻": "fonctions 💻",
        "Math 🤯": "Mathématiques 🤯",
        "Arrays ⛓": "Les tableaux ⛓",
        "Variables 🤹": "Variables 🤹",
        "💐 Local variables": "💐 variables locales",
        "🏫 Classroom variables": "🏫 variables en classe",
        "Create variable...": "Créer une variable ...",
        "RGB Color": "Couleur RVB",
        "Color Sensor": "Capteur de couleur",
        "Single LED": "LED unique",
        "RGB-LED": "RGB-LED",
        "4-digit Display": "Affichage à 4 chiffres",
        "8x8 LED Matrix": "Matrice DEL 8x8"
      },
      "beginner.xml": {
        "Motion 🚗": "Mouvement 🚗",
        "Free Movement": "Mouvement libre",
        "Mat Movement": "mat Mouvement",
        "Special": "Spécial",
        "Bits 💡": "Morceaux 💡",
        "Sound 🙊": "Du son 🙊",
        "Sight 🙈": "Vue 🙈",
        "Events 🎈": "événements 🎈",
        "Timing ⌛️": "Timing ⌛️",
        "Print 📝": "Impression 📝",
        "Loops 💈": "boucles 💈",
        "Math 🤯": "Math 🤯",
        "Sensing 📐": "détection 📐"
      }
    },
    "workspaces/basic/index.js": {
      "WARNING": "ATTENTION",
      "Block ID": "ID bloc"
    },
    "codeChecker.js": {
      "First configure the sensor for this robot.": "Tout d'abord configurer le capteur pour ce robot.",
      "Robot {{robotId}} is not exist!": "Robot {{robotId}} est existe pas!",
      "The Robot 🤖️ {{robotIdentifier}} is offline.": "Le Robot 🤖️ {{robotIdentifier}} est hors-ligne."
    },
    "ClassRoom/index.jsx": {
      "Oops! Error on obtain classroom info.": "Oops! Erreur sur obtenir les informations en classe.",
      "Are you sure?": "Êtes-vous sûr?",
      "Your code is running, do you want to stop it?": "Votre code est en cours d'exécution, voulez-vous arrêter?",
      "Yes": "Oui",
      "Oops!!  Fix errors highlighted in yellow blocks marked with \"⚠️\"": "Oops!! Correction des erreurs mises en évidence dans les blocs jaunes marqués « ⚠️ »",
      "Program error": "erreur de programme",
      "Oops! - AI Navigation did not find a pathway to get to the desination.": "Oops! - AI Navigation n'a pas trouvé une voie pour arriver au desination."
    },
    "sensor_specs.js": {
      "Gripper": "Gripper",
      "Open": "Ouvert",
      "Closed": "Fermé",
      "Bright LED": "Bright LED",
      "RGB-LED": "RGB-LED",
      "buzzer": "avertisseur sonore",
      "IR transmitter": "émetteur IR",
      "Relay": "Relais",
      "On": "Sur",
      "Off": "De",
      "Ultrasonic Distance Sensor": "Capteur de distance à ultrasons",
      "Waterproof Temperature Sensor": "Capteur de température étanche",
      "humidity sensor": "capteur d'humidité",
      "Temp & Humidity Sensor": "Temp et capteur d'humidité",
      "Temperature": "Température",
      "Humidity": "Humidité",
      "IR receiver": "récepteur IR",
      "push button": "bouton-poussoir",
      "Push Button": "Appuyez sur le bouton",
      "magnetic sensor": "capteur magnétique",
      "Magnetic Sensor": "Capteur magnétique",
      "vibration sensor": "capteur de vibrations",
      "Tilt Switch": "Commutateur d'inclinaison",
      "knock sensor": "détecteur de cliquetis",
      "Touch Sensor": "Senseur tactile",
      "Touching": "Émouvant",
      "Idle": "Tourner au ralenti",
      "sound sensor": "un capteur de sons",
      "PIR Motion Sensor": "PIR capteur de mouvement",
      "Triggered": "déclenché",
      "limit switch": "fin de course",
      "Switch": "Commutateur",
      "IR Reflective Sensor": "Réflexion Infrarouge",
      "Reflection detected": "réflexion détectée",
      "No reflection": "Pas de réflexion",
      "Rotary Angel Sensor": "Rotary Ange capteur",
      "degree": "degré",
      "Angle": "Angle",
      "Moisture Sensor": "Capteur d'humidité",
      "Moisture ratio": "taux d'humidité",
      "Light Sensor": "Capteur de lumière",
      "Brightness": "Luminosité",
      "steam sensor": "détecteur de vapeur",
      "Water Sensor": "Détecteur d'eau",
      "Water index": "Indice de l'eau",
      "Air Quality Sensor": "Air Quality Sensor",
      "Air pollution index": "Indice de la pollution atmosphérique",
      "Sound Sensor": "Capteur sonore",
      "Sound level": "Niveau sonore",
      "Linear Potentiometer": "linéaire Potentiometer",
      "Slide position index": "Faites glisser l'index de position",
      "UV Sensor": "UV capteur",
      "UV strength": "force UV",
      "Pulse Sensor": "Capteur d'impulsion",
      "Pulse level": "niveau d'impulsion",
      "PH Sensor": "Capteur de PH",
      "PH index": "Indice de PH",
      "MEMS Microphone": "MEMS Microphone",
      "3-Axis Digital Compass": "3 axes Boussole numérique",
      "Gauss": "Gauss",
      "MPU6050 Accelerometer & Gyro": "MPU6050 Accéléromètre & Gyro",
      "acceleration": "accélération",
      "gravity": "la gravité",
      "Color Sensor": "Capteur de couleur",
      "Color": "Couleur",
      "Laser Ranging Sensor": "Capteur laser Allant",
      "Distance": "Distance",
      "4-Digit Display": "Affichage à 4 chiffres",
      "Number": "Nombre",
      "LED Matrix": "Matrice de LED",
      "I2C EEPROM": "EEPROM I2C",
      "Text": "Texte",
      "Weight Sensor": "Capteur de poids",
      "Gram": "Gramme",
      "BME280 Atmospheric Sensor": "BME280 atmosphérique capteur",
      "Barometric pressure": "Pression barométrique",
      "Barometric": "Barométrique",
      "BMP180 Barometer": "Baromètre BMP180"
    },
    "src/routes/ClassRoomRobots/components/Menu.jsx": {
      "Views": "vues",
      "Robots": "robots"
    },
    "ListMenu.jsx": {
      "Are you sure?": "Êtes-vous sûr?",
      "You won't be able to revert back!": "Vous ne serez pas en mesure de revenir en arrière!",
      "Yes, delete it!": "Oui, supprimez-le!",
      "Print": "Impression",
      "Remove": "Retirer",
      "Add Student": "Ajouter étudiant",
      "Save Changes": "Sauvegarder les modifications"
    },
    "StudentCard.jsx": {
      "Name...": "Nom..."
    },
    "StudentRow.jsx": {
      "Oop! Student {{studentName}} already exists": "Oop! Étudiant {{studentName}} existe déjà",
      "Name...": "Nom...",
      "Select robot...": "Choisir le robot ..."
    },
    "src/routes/ClassRoomStudents/components/TopMenu.jsx": {
      "Students": "Élèves"
    },
    "ClassRoomStudent/index.jsx": {
      "Oop! Error on loading classroom": "Oop! Erreur sur la classe de chargement",
      "Student names saved.": "Les noms d'étudiants enregistrés.",
      "Print student login cards?": "Imprimer des cartes de connexion étudiant?",
      "No": "Non",
      "Yes": "Oui",
      "Oops!! Some students already added to this classroom": "Oops!! Certains étudiants déjà ajoutés à cette salle de classe",
      "Error on saving students": "Erreur sur les étudiants d'économie",
      "Error with the import file": "Erreur avec le fichier d'importation",
      "Oops...": "Oops...",
      "Error on adding student": "Erreur sur l'ajout d'étudiant",
      "Add students to classroom": "Ajouter aux élèves en classe",
      "You have two options": "Vous avez deux options",
      "Upload list of student names.": "Téléchargez la liste des noms des étudiants.",
      "Download template": "Télécharger le kit graphique",
      "Select file": "Choisir le dossier",
      "Add list of student names below.": "Ajouter la liste des noms des étudiants ci-dessous.",
      "Import": "Importation",
      "Print Student Login Cards": "Imprimer Cartes étudiants Connexion"
    },
    "Home/index.jsx": {
      "I'm a": "Je suis",
      "TEACHER": "PROF",
      "STUDENT": "ÉTUDIANT"
    },
    "RecoveryPassword/Breadcrumb.jsx": {
      "Recovery password": "mot de passe de récupération",
      "I'm": "je suis"
    },
    "RecoveryPassword/index.js": {
      "I remember": "Je me souviens",
      "Recover": "Récupérer"
    },
    "StudentLogin/Breadcrumb.jsx": {
      "I'm a student": "Je suis étudiant",
      "Login": "S'identifier"
    },
    "StudentLogin/index.jsx": {
      "Oops!!": "Oops!!"
    },
    "StudentRegister/Breadcrumb.jsx": {
      "I'm a student": "Je suis étudiant",
      "Register": "S'inscrire"
    },
    "StudentRegister/index.jsx": {
      "Error on register": "Erreur sur le registre",
      "Hi student ...": "Salut étudiant ...",
      "Login": "S'identifier",
      "Your username": "Ton nom d'utilisateur",
      "Password": "Mot de passe",
      "Classroom ID": "ID classe"
    },
    "src/routes/StudentRobots/components/Menu.jsx": {
      "Robots": "robots"
    },
    "src/routes/StudentRobots/components/Robot.jsx": {
      "Sensors": "capteurs"
    },
    "components/SensorsConfiguration.jsx": {
      "func:getSensors1": {
        "(none)": "(aucun)",
        "Piranha LED": "LED Piranha",
        "RGB LED array": "matrice LED RGB",
        "Buzzer": "Avertisseur sonore",
        "IR transmitter": "émetteur IR",
        "Relay": "Relais",
        "Temperature": "Température",
        "Temperature & humidity": "Température et humidité",
        "IR receiver": "récepteur IR",
        "Push button": "Bouton poussoir",
        "Magnetic sensor": "Capteur magnétique",
        "Vibration sensor": "Capteur de vibration",
        "Tilt Sensor": "Capteur d'inclinaison",
        "Knock sensor": "Détecteur de cliquetis",
        "Touch sensor": "Senseur tactile",
        "Sound sensor": "capteur sonore",
        "Human Body Infrared Sensor": "Capteur infrarouge corps humain",
        "Limit switch": "Fin de course",
        "Opto-coupler": "Opto-coupleur"
      },
      "func:getSensors2": {
        "(none)": "(aucun)",
        "Compass": "Boussole",
        "Accelerometer": "accéléromètre",
        "Gyroscope": "Gyroscope",
        "OLED screen": "écran OLED"
      },
      "func:getSensors3": {
        "(none)": "(aucun)",
        "Rotation sensor": "Capteur de rotation",
        "Moisture sensor": "Capteur d'humidité",
        "Photo resistor": "résistance photo",
        "Steam sensor": "Capteur de vapeur",
        "Water level sensor": "Capteur de niveau d'eau",
        "Smog senser": "smog Senser",
        "Alcohol sensor": "Capteur d'alcool",
        "Light sensor": "Capteur de lumière",
        "UV rays sensor": "Capteur de rayons UV",
        "IR obstacle sensor": "capteur d'obstacle IR"
      },
      "func:getSensors4": {
        "(none)": "(aucun)",
        "Servo": "servo"
      },
      "func:getSensors5": {
        "(none)": "(aucun)",
        "Distance sensor": "Capteur de distance"
      },
      "Ops.. something went wrong": "Ops .. quelque chose a mal tourné"
    },
    "StudentRobots/StudentRobots.jsx": {
      "Oops! Error on loading classroom: {{classroomName}}": "Oops! Erreur sur la classe de chargement: {{classroomName}}"
    },
    "components/ClassRoomItem.jsx": {
      "Scan the class code with the \"Kai's Eye\" phone": "Scannez le code de classe avec le téléphone « Eye Kai »",
      "or": "ou",
      "using with the \"Kai's Virtual Viewer\" found in the app store.": "en utilisant le « Viewer virtuel de Kai » trouvé dans le magasin d'application.",
      "Are you sure?": "Êtes-vous sûr?",
      "You won't be able to recover this! {{itemName}} will be lost.": "Vous ne serez pas en mesure de récupérer cela! {{itemName}} sera perdu.",
      "Yes, delete it!": "Oui, supprimez-le!",
      "No, cancel!": "Non, annuler!",
      "No students loaded yet": "Aucun élève encore chargé",
      "Removing classroom": "Suppression de classe",
      "Back to your classroom": "Retour à votre salle de classe",
      "Join": "Joindre",
      "Scan with Kai's Eye phone app": "Scan avec l'application de téléphone Eye Kai",
      "Created": "Établi"
    },
    "components/NewClassRoom.jsx": {
      "Create a new classroom": "Créer une nouvelle salle de classe",
      "Class name (required)": "Nom de la classe (obligatoire)",
      "Request failed": "Demande échoué",
      "Scan the class code with the \"Kai's Eye\" phone": "Scannez le code de classe avec le téléphone « Eye Kai »",
      "or": "ou",
      "using with the \"Kai's Virtual Viewer\" found in the app store.": "en utilisant le « Viewer virtuel de Kai » trouvé dans le magasin d'application."
    },
    "SwitchClassroom/index.jsx": {
      "Classrooms": "Salles de classe",
      "Oops! Error on get classes": "Oops! Erreur sur les classes get",
      "Oops! Error on joining class {{classroomId}}": "Oops! Erreur sur la classe de rejoindre {{classroomId}}",
      "Join a classroom to get started": "Joignez-vous à une salle de classe pour commencer",
      "Loading classrooms": "salles de classe de chargement"
    },
    "TeacherLogin/Breadcrumb.jsx": {
      "I'm a teacher": "je suis un enseignant",
      "login": "s'identifier"
    },
    "TeacherLogin/index.jsx": {
      "Register": "S'inscrire",
      "Password": "Mot de passe",
      "I forgot my password": "j'ai oublié mon mot de passe",
      "E-mail": "Email"
    },
    "TeacherRegister/Breadcrumb.jsx": {
      "I'm a teacher": "je suis un enseignant",
      "Register": "S'inscrire"
    },
    "TeacherRegister/index.jsx": {
      "Error on register": "Erreur sur le registre",
      "Hi teacher ...": "Salut enseignant ...",
      "Your name": "Votre nom",
      "Password": "Mot de passe",
      "Woo Hoo - nearly there!": "Woo Hoo - presque là!",
      "Please check the verification email in your inbox or spam folder.": "S'il vous plaît vérifier l'e-mail de vérification dans votre boîte de réception ou d'un dossier de spam.",
      "If you don't receive a verification email, contact <a href=\"mailto:support@kaisclan.ai\">support@kaisclan.ai</a>": "Si vous ne recevez pas un message de vérification, contactez <a href=\"mailto:support@kaisclan.ai\"> support@kaisclan.ai </a>"
    },
    "TeacherReset/Breadcrumb.jsx": {
      "I'm a teacher": "je suis un enseignant",
      "Reset password": "Réinitialiser le mot de passe"
    },
    "TeacherReset/index.jsx": {
      "New Password": "nouveau mot de passe",
      "I remember": "Je me souviens",
      "Reset": "Réinitialiser"
    },
    "UserVerify/index.jsx": {
      "Verifying...": "Vérification ...",
      "Woo hoo!! Kai's Clan account has now been verified": "Woo hoo !! Kai compte du clan a été vérifié",
      "Click here to login": "Cliquez ici pour vous identifier"
    },
    "WebGL/SeparateWindow/index.jsx": {
      "Click to load 3D virtual environment.": "Cliquez pour charger environnement virtuel 3D."
    },
    "store/models/Project.js": {
      "Junior": "Junior",
      "Intermediate": "Intermédiaire",
      "Advanced": "Avancée",
      "Expert": "Expert"
    },
    "store/classRoomStore.js": {
      "You are Admin, save successful.": "Vous êtes Admin, enregistrer avec succès.",
      "Error on saving your new work": "Erreur lors de l'enregistrement de votre nouveau travail",
      "Oops!! Save failed, you are not the owner of this project.": "Oops!! Échec de l'enregistrement, vous n'êtes pas le propriétaire de ce projet.",
      "Save this as your project so you don't loose any work": "Enregistrer ce que votre projet de sorte que vous ne perdez aucun travail",
      "Enter a new classroom variable name": "Entrez un nouveau nom variable en classe",
      "Enter a new classroom variable value": "Entrez une nouvelle valeur variable en classe",
      "Read Only?": "Lecture seulement?",
      "New classroom variable {{variableName}} saved": "Nouvelle variable en classe {{variableName}} enregistré",
      "Robot  {{robotId}} does not exist in this class room": "Robot {{robotId}} n'existe pas dans cette salle de classe",
      "This robot's sensors register state is {{state}} now, you can not send another request in this situation.": "Les capteurs de ce robot registre d'état est {{state}} maintenant, vous ne pouvez pas envoyer une autre demande dans cette situation.",
      "Oops! Something went wrong - Response data from robot does not match with request data.": "Oops! Quelque chose a mal tourné - les données de réponse de robot ne correspondent pas aux données de demande.",
      "Please check Robot {{robotId}} if online.": "S'il vous plaît vérifier Robot {{robotId}} si en ligne."
    },
    "store/teacherRecoveryStore.js": {
      "Please, check the errors bellow.": "S'il vous plaît, vérifier les erreurs ci-dessous.",
      "Please, check your e-mail.": "Merci de consulter vos emails."
    },
    "store/teacherResetStore.js": {
      "Please, check the errors bellow.": "S'il vous plaît, vérifier les erreurs ci-dessous."
    },
    "store/userMediaStore.js": {
      "Files need to be smaller that 1MB": "Les fichiers doivent être plus petits que 1MB"
    },
    "store/userStore.js": {
      "Please, check your e-mail.": "Merci de consulter vos emails."
    },
    "utils/index.js": {
      "The interval has alreadly stopped.": "L'intervalle a alreadly arrêté."
    },
    "src/components/QRScanner/index.jsx": {
      "Allow your browser to access your camera or login on the right.": "Laissez votre navigateur pour accéder à votre appareil photo ou ouvrir une session sur la droite."
    },
    "src/routes/QR/index.jsx": {
      "Redirecting...": "Redirigeant ..."
    },
    "src/routes/StudentLogin/StudentLoginForm.jsx": {
      "Check you entered your username and classroom code correctly.": "Vérifiez que vous avez entré votre nom d'utilisateur et le code classe correctement.",
      "QR Code Login": "QR Code Connexion",
      "Don't have a QR code?": "Ne pas avoir un code QR?",
      "Ask your teacher to print it out for you.": "Demandez à votre professeur pour l'imprimer pour vous.",
      "Login": "S'identifier",
      "Don't have an account?": "Ne pas avoir un compte?",
      "Ask your teacher to create one for you.": "Demandez à votre enseignant de créer pour vous.",
      "Oops! Error with login, try again.": "Oops! Erreur avec connexion, essayez à nouveau."
    },
    "src/routes/ClassRoomStudentsLoginCards/index.jsx": {
      "Error on loading classroom": "Erreur sur la classe de chargement",
      "PRINT": "IMPRESSION",
      "Print Login Cards": "Imprimer Cartes Connexion"
    },
    "src/routes/ClassRoomStudentsLoginCards/components/TopMenu.jsx": {
      "Views": "vues",
      "Login Card": "Connexion Carte"
    },
    "src/routes/ClassRoomStudentsLoginCards/StudentLoginCard/index.jsx": {
      "Go to": "Aller à",
      "Login with your name and code": "Connectez-vous avec votre nom et le code",
      "Scan the QR code above": "Scannez le code QR ci-dessus"
    },
    "src/routes/ClassRoom/components/ProjectList/ProjectRule.jsx": {
      "Are you sure?": "Êtes-vous sûr?",
      "You won't be able to recover this! {{ruleName}} will be lost.": "Vous ne serez pas en mesure de récupérer cela! {{ruleName}} sera perdu.",
      "Yes, delete it!": "Oui, supprimez-le!",
      "No, cancel!": "Non, annuler!"
    },
    "map_specs.js": {
      "func:onFireFrozenEffectThingFactory": {
        "OnFire": "Sur le feu",
        "Frozen": "Congelé"
      },
      "Create": "Créer",
      "Smart city": "Ville intelligente",
      "Project life stream": "Cycle de vie d'un projet", // French = Project life cycle
      "Rescue run": "course de sauvetage",
      "Automated warehouse": "entrepôt automatisé",
      "Mars discovery": "découverte Mars"
    },
    'NewStudentConfigurationItem.jsx': {
      '🚀 Shortcut: Copy then paste the student list from Excel to here.': `🚀 Raccourci: Copiez puis collez la liste des étudiants d'Excel ici.`,
      '🚀Type a student name(Do not use the real name) and press Enter': `🚀 Tapez un nom d’étudiant (n’utilisez pas le nom réel) et appuyez sur Entrée.`,
    },
    'ViewsStudentConfiguration.jsx': {
      'Student names saved.': `Noms d'étudiants enregistrés.`,
      'Print student login cards?': `Imprimer les cartes de connexion des étudiants?`,
      'No': 'Non',
      'Yes': 'Oui',
      'Oops!! Some students already added to this classroom': `Oops!! Certains étudiants ont déjà été ajoutés à cette classe`,
      'Error on saving students': `Erreur lors du sauvetage des étudiants`,
      'Are you sure?': `Êtes-vous sûr?`,
      [`You won't be able to revert back!`]: `Vous ne pourrez pas revenir en arrière!`,
      'Yes, delete it!': 'Oui, supprimez-le!',
      'Students': 'Étudiantes',
      'Print Student Login Cards': `Imprimer les cartes de connexion des étudiants`,
      'Save changes': `Sauvegarder les modifications`,
      'Remove': 'Retirer',
    },
    'ViewsRobotConfiguration.jsx': {
      'Empty this slot': `Vide cette fente`,
      'Cancel': `Annuler`,
      'Save': `enregistrer`,
      'Search sensors...': `Capteurs de recherche ...`,
      'Robot': `Robot`,
      'No Sensor be found!': `Aucun capteur ne soit trouvé!`,
      'Unselect': `Désélectionner`,
      "Saved": "Enregistré",
      "Oops... no robots found!": "Oups ... pas de robots trouvé!",
      "Please add at least one robot": "S'il vous plaît ajouter au moins un robot",
      'Robot sensor bits': 'Embouts de capteur de robot',
      'Robot Offline': 'Robot hors ligne',
    },
    'CollapseButtonGroup/index.jsx': {
      'Are you sure?': 'Êtes-vous sûr?',
      [`Your changes will be lost if you don't save them.`]: 'Vos modifications seront perdues si vous ne les enregistrez pas.',
      [`Don't Save`]: 'Ne sauvez pas',
      'Cancel': 'Annuler',
    },
  },
});
